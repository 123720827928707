const modalInitialState = {
  isCard: false,
  isFullheight: false,
  isActive: false,
  content: null,
  heading: "",
  headerClass: null,
  hideCloseButton: false,
  background: {
    color: null,
    opacity: "100",
  },
  disableCloseModal: false,
}

const patientEnrolmentIntialValues = {
  firstName: "",
  lastName: "",
  middleInitial: "",
  // birthday: {
  //   month: { value: null },
  //   date: { value: null },
  //   year: "",
  // },
  mobileNumber: "",
  emailAddress: "",
  deliveryAddress: {
    addressType: "",
    streetAddress: "",
    city: { value: "" },
    clinicHours: "",
    barangay: { value: "" },
    province: { value: "Metro Manila", label: "Metro Manila" },
    preferredDeliveryTime: "",
    notes: "",
  },
  secondaryContactFirstName: "",
  secondaryContactLastName: "",
  secondaryContactMobileNumber: "",
  prescribingDoctor: "",
  hospitalClinic: {
    value: "",
    label: "",
  },
  otherHospital: "",
  nominationCode: "",
}

const patientNomination = {
  firstName: "",
  lastName: "",
  emailAddress: "",
  mobileNumber: "",
  prcNumber: "",
  specialization: { value: "", label: "" },
  otherSpecialization: "",
  patientFirstName: "",
  patientLastName: "",
  patientMobileNumber: "",
  patientEmailAddress: "",
  patientAlreadyPrescribed: [],
}

export const initialState = {
  modal: { ...modalInitialState },
  patientEnrollment: patientEnrolmentIntialValues,
  patientNomination: patientNomination,
  hasAcknowledge: [],
  documents: [],
  consentAndAuth: [],
  toast: {
    isActive: false,
    message: null,
    color: null,
  },
}
